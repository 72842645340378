import { useState, useRef, useEffect } from "react";
import { RiMenuLine, RiCloseLine } from "react-icons/ri";
import { useForceUpdate, useWindowScroll } from "@mantine/hooks";

import brandIcon from "../../images/brandIcon.svg";

const Header = () => {
  const hidden = useRef(false);
  const update = useForceUpdate();
  const [scroll] = useWindowScroll();
  const scrollY = useRef(scroll.y);

  useEffect(() => {
    if (scrollY.current > scroll.y) {
      if (scroll.y === 0) {
        hidden.current = false;
      }
      if (hidden.current) {
        hidden.current = false;
        return update();
      }
    }

    if (scrollY.current < scroll.y) {
      if (!hidden.current) {
        hidden.current = true;
        return update();
      }
    }
    scrollY.current = scroll.y;
  });

  const handleClickProject = () => {
    document.getElementById("project").scrollIntoView();
    setMobMenu(false);
  };

  const handleClickContactMe = () => {
    document.getElementById("contactMe").scrollIntoView();
    setMobMenu(false);
  };

  const headleClickHero = () => {
    document.getElementById("hero").scrollIntoView();
    setMobMenu(false);
  };
  const [mobMenu, setMobMenu] = useState(false);

  return (
    <div
      className="fixed transition-transform top-0 duration-300 ease-in-out w-full h-36 z-[100] bg-[#111418]/50 "
      style={{
        transform: hidden.current ? "translateY(-158px)" : "translateY(0)",
      }}
    >
      <div className="w-full max-w-[1190px] mx-auto">
        <div className=" w-full px-4 lg:px-0 z-[100] mt-[37px]">
          <div className="flex max-w-[1190px]  w-full items-center h-full justify-between">
            <img
              src={brandIcon}
              width={75}
              height={75}
              alt="brandIconHeader"
              onClick={headleClickHero}
              className="hover:cursor-pointer"
            />

            {/* Desktop & Tablet menu */}
            <div className="hidden lg:block">
              <ul className="flex flex-row space-x-10">
                <li>
                  <p
                    className="hover:cursor-pointer font-poppins text-xl leading-[27px] font-[500] text-[white]"
                    onClick={handleClickProject}
                  >
                    Projects
                  </p>
                </li>
                <li>
                  <p
                    className="hover:cursor-pointer font-poppins text-xl leading-[27px] font-[500] text-[white]"
                    onClick={handleClickContactMe}
                  >
                    Contact
                  </p>
                </li>
              </ul>
            </div>
            <div className="lg:hidden">
              <RiMenuLine
                color="white"
                size={30}
                onClick={() => setMobMenu(!mobMenu)}
                className="hover:cursor-pointer"
              />
            </div>
          </div>

          <div
            className={`md:hidden  ${
              mobMenu ? "left-0" : "left-[-100%]"
            } w-full fixed h-screen bg-black/70 top-0 left-0 flex justify-end`}
          >
            <div
              className={`z-[100] fixed w-[70%] rounded-sm bg-gradient-to-b from-[#111418]/90 to-white/50  h-screen top-0 right-0 ${
                mobMenu ? "translate-x-[0%]" : "translate-x-[100%]"
              } transition ease-out duration-700 p-5`}
            >
              <div>
                <div className="flex flex-row  items-center mt-5 justify-between">
                  <img
                    src={brandIcon}
                    width={35}
                    height={35}
                    alt="brandIconHeader"
                    onClick={headleClickHero}
                    className="hover:cursor-pointer"
                  />
                  <div>
                    <RiCloseLine
                      color="white"
                      size={30}
                      onClick={() => setMobMenu(!mobMenu)}
                      className="hover:cursor-pointer"
                    />
                  </div>
                </div>

                <div className="">
                  <ul className="flex flex-col space-y-10 mt-24">
                    <li>
                      <p
                        className="hover:text-[#fc1111] font-poppins text-xl leading-[27px] font-[500] text-[white]"
                        onClick={handleClickProject}
                      >
                        Projects
                      </p>
                    </li>
                    <li>
                      <p
                        className="hover:text-[#fc1111] font-poppins text-xl leading-[27px] font-[500] text-[white]"
                        onClick={handleClickContactMe}
                      >
                        Contact
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
