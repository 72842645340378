import { client } from "./client";

const heroTextQuery = client.fetch(`*[_type == "heroText"]`);

const skillsQuery = client.fetch(
  `*[_type == "skills"]{languageName, languageImage{asset->}, order} | order(order asc)`
);

const projectQuery = client.fetch(
  `*[_type == "projects"]{..., imageOfProject{asset->}, order} | order(order asc)`
);

const educationQuery = client.fetch(
  `*[_type == "education"] | order(order asc)`
);

const experinceQuery = client.fetch(
  `*[_type == "experience"] | order(order asc)`
);

const extraCurricularQuery = client.fetch(`*[_type == "extraCurricular"]`);

const resumeQuery = client.fetch(`*[_type == 'resume'] {...,
 "file":resumeFile.asset->url
}`);
export {
  heroTextQuery,
  skillsQuery,
  projectQuery,
  educationQuery,
  experinceQuery,
  extraCurricularQuery,
  resumeQuery,
};
