import { useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Projects as SanityTypeProject } from "../../SanitySchemaTypes/schemaType";

import "./project.css";

interface ProjectFilterHelperProps {
  allProjects: {
    current: SanityTypeProject[] | [];
  };
  setProjectLists: React.Dispatch<
    React.SetStateAction<SanityTypeProject[] | undefined>
  >;
}

const filterOptions = [
  "All",
  "CSS",
  "Data Structure",
  "JavaScript",
  "Machine Learning",
  "MongoDB",
  "React",
];

const ProjectFilterHelper = ({
  allProjects,
  setProjectLists,
}: ProjectFilterHelperProps) => {
  const [open, setOpen] = useState(false);

  const [filterText, setFilterText] = useState("All");

  const handleFilterMenuOpen = () => {
    setOpen(!open);
  };

  const handleClick = (value: string) => {
    // if the value is all, set all projectLists
    if (value === "All") {
      setProjectLists(allProjects.current);
      setFilterText("All");
      return;
    }
    const withOutSpaceValue = value.replace(" ", "");
    const filterProjectList = allProjects.current.filter((item) =>
      item?.tagOfProject?.some(
        (item) => item.toLowerCase() === withOutSpaceValue.toLowerCase()
      )
    );

    setFilterText(value);
    setProjectLists(filterProjectList);
  };

  return (
    <div onClick={handleFilterMenuOpen}>
      <div className="max-w-[209px] w-[209px] h-[50px] flex flex-row space-x-5 justify-center items-center p-3 border-2 border-[#FC1111]  bg-[#111418] rounded-lg hover:cursor-pointer ">
        <p className=" text-sm text-center  font-[500] leading-[50px]  text-[#F5F5F5] font-poppins">
          {filterText}
        </p>
        {!open && (
          <IoMdArrowDropdown color="#FC1111" width={"13px"} height={"7px"} />
        )}
        {open && (
          <IoMdArrowDropup color="#FC1111" width={"13px"} height={"7px"} />
        )}
      </div>

      <div
        className={`absolute z-[400]  ${open ? " filterMenuClass" : "hidden"} `}
      >
        <div className="mt-7  w-[206px] rounded-[10px] p-5 bg-[#111418] border-2 border-[#fc1111]">
          {filterOptions.map((item, index) => (
            <p
              key={index}
              className="hover:cursor-pointer hover:underline text-[18px] py-2 font-[700] leading-[27px] text-white font-poppins"
              onClick={(e) => {
                handleClick(item);
              }}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectFilterHelper;
