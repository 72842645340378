import { ExtraCurricular as SanityTypeExtraCurricular } from "../../SanitySchemaTypes/schemaType";

import university from "../../images/university.svg";

export interface ExtraCurricularProps {
  extraCurricular: SanityTypeExtraCurricular[];
}
const ExtraCurricular = ({ extraCurricular }: ExtraCurricularProps) => {
  return (
    <div className="relative flex flex-col justify-center items-center md:justify-start md:items-start space-y-16 md:space-y-0 md:flex-row md:space-x-48">
      <div className="md:absolute w-[150px] h-[150px] gap-10 flex justify-center items-center bg-[#111418] border-2 border-[#FC1111] rounded-full">
        <img src={university} alt="work" />
      </div>
      <div className="w-full flex flex-col space-y-8">
        {extraCurricular?.map((item, index) => {
          return (
            <div key={index} className="w-full flex flex-col space-y-2">
              <div className="w-full  flex flex-row  justify-between items-center">
                <a
                  href={item.urlOfCertificate}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="font-poppins font-[300] hover:text-[#FC1111] text-xl leading-[20px] lg:text-3xl lg:leading-[48px]  md:text-left text-[#F5F5F5]">
                    {item.nameOfActivity}
                  </p>
                </a>
                <p className="font-poppins font-[300]  text-xl leading-[20px] lg:text-3xl lg:leading-[48px]  text-end  text-[#F5F5F5]">
                  {item.yearOfStarting} -{" "}
                  {item.yearOfEnd !== 0 ? item.yearOfEnd : "Present"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExtraCurricular;
