import { useEffect, useState } from "react";
import {
  Experience as SanityTypeExperience,
  ExtraCurricular as SanityTypeExtraCurricular,
  Education as SanityTypeEducation,
} from "../../SanitySchemaTypes/schemaType";

import line from "../../images/line.png";
import {
  educationQuery,
  experinceQuery,
  extraCurricularQuery,
} from "../../lib/query";
import ContactMe from "./ContactMe";
import Education from "./Education";
import Experience from "./Experience";
import ExtraCurricular from "./ExtraCurricular";

const About = () => {
  const [education, setEducation] = useState<SanityTypeEducation[]>();

  const [experience, setExperience] = useState<SanityTypeExperience[]>();

  const [extraCurricular, setExtraCurricular] =
    useState<SanityTypeExtraCurricular[]>();

  useEffect(() => {
    educationQuery.then((data) => setEducation(data));
    experinceQuery.then((data) => setExperience(data));
    extraCurricularQuery.then((data) => setExtraCurricular(data));
  }, []);

  return (
    <div id="about">
      <div className="flex flex-col justify-center items-center space-y-10">
        <h1 className="font-poppins font-[500] text-xl leading-[27px] text-center text-[#F5F5F5]">
          About
        </h1>
        <img src={line} alt="line" />
      </div>
      <div data-aos="fade-up" className="flex flex-col space-y-16 mt-16">
        {experience && <Experience experience={experience} />}
      </div>

      <div className="flex flex-col space-y-16 mt-16" data-aos="fade-up">
        <div className="relative flex flex-col justify-center items-center md:justify-start md:items-start space-y-16 md:space-y-0 md:flex-row md:space-x-48">
          {education && <Education education={education} />}
        </div>
      </div>

      <div data-aos="fade-up" className="flex flex-col space-y-16 mt-16">
        {extraCurricular && (
          <ExtraCurricular extraCurricular={extraCurricular} />
        )}
      </div>

      <div
        className="flex flex-col mt-32 lg:mt-56 justify-center items-center"
        id="contactMe"
        data-aos="fade-up"
      >
        <ContactMe />
      </div>
    </div>
  );
};

export default About;
