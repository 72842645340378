import React, { useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { GrLinkedinOption } from "react-icons/gr";
import { FaGithubAlt } from "react-icons/fa";
import { HeroText } from "../../SanitySchemaTypes/schemaType";

import { heroTextQuery, resumeQuery } from "../../lib/query";
import code from "../../images/code.png";
import "./hero.css";

const Hero = () => {
  // still left to call the resume from Sanity
  const [heroText, setHeroText] = useState<HeroText>();

  const [resumeFile, setResumeFile] = useState<string>();

  const handleClick = () => {
    document.getElementById("about")?.scrollIntoView();
  };

  useEffect(() => {
    heroTextQuery.then((data) => {
      setHeroText(data[0]);
    });

    resumeQuery.then((data) => {
      setResumeFile(data[0].file);
    });
  }, []);

  return (
    <div className="flex flex-col " data-aos="fade-up" id="hero">
      <div className="flex flex-col pt-64 md:flex-row md:justify-between md:items-center">
        {/* hello Pranav section*/}
        <div className="flex flex-col justify-center items-center md:justify-start md:items-start md:space-y-0">
          <h1 className="text-5xl leading-[97px] lg:text-[6rem] lg:leading-[9rem] font-[500] font-poppins text-white">
            નમસ્તે 🙏
          </h1>
          <h1 className="text-6xl leading-[97px] lg:text-[6rem] lg:leading-[9rem] font-[700] font-poppins text-[#FC1111]">
            I'm Pranav!
          </h1>
          {/* use color config here*/}
          <h1 className=" font-poppins font-[300] lg:leading-[48px] lg:text-4xl lg:mt-20 text-[#F5F5F5]">
            <div className="wrapper h-[25px] lg:h-[50px] flex space-x-2">
              <p className="ml-14 lg:ml-0">A </p>
              <div className="words">
                <span> computer engineer 🧑‍💻</span>
                <span> web developer</span>
                <span> stargazer 🌟 </span>
                <span> sports enthusiast 🏏</span>
                <span> computer engineer 🧑‍💻</span>
              </div>
            </div>
          </h1>

          <div className="pt-16 lg:pt-20">
            <div
              className="w-[293px] flex justify-center items-center p-3 border-2 border-[#FC1111]  bg-[#111418] rounded-lg hover:cursor-pointer "
              onClick={handleClick}
            >
              <p className=" text-xl  text-center   text-[#F5F5F5] font-poppins">
                About me →
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="relative flex flex-row justify-center items-center mt-10 lg:mt-0 space-x-10 md:space-x-0 md:flex-col md:space-y-16">
            <a
              href="mailto:pranav.patel292@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <MdEmail size={"40px"} color={"#F5F5F5"} />
            </a>

            <a
              href="https://github.com/PranavPatel292"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithubAlt
                size={"40px"}
                color={"#F5F5F5"}
                className="hover:cursor-pointer"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/pranavpatel292/"
              target="_blank"
              rel="noreferrer"
            >
              <GrLinkedinOption
                size={"40px"}
                color={"#F5F5F5"}
                className="hover:cursor-pointer "
              />
            </a>
          </div>
        </div>

        <div className="hidden lg:block opacity-70 absolute  w-[281px] left-[58%] right-[34.32%]  top-[39.27%] ">
          <img src={code} alt="code" />
        </div>
      </div>

      <div className="mt-64">
        <p className="text-3xl font-[300] text-center leading-[48px] text-[#F5F5F5] font-poppins">
          {heroText?.heroText}
        </p>
      </div>

      <div className="mt-24 flex justify-center items-center">
        <a href={resumeFile} target="_blank" rel="noreferrer" download>
          <div className="w-[293px] flex flex-row  space-x-5 justify-center items-center p-3 border-2 border-[#FC1111]  bg-[#111418] rounded-lg hover:cursor-pointer ">
            <p className=" text-xl  text-center   text-[#F5F5F5] font-poppins">
              Resume →
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Hero;
