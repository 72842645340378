import "./project.css";
import { urlFor } from "../../lib/client";

import { Projects as SanityTypeProject } from "../../SanitySchemaTypes/schemaType";

interface CardProps {
  item: SanityTypeProject;
  setProjectLists: React.Dispatch<
    React.SetStateAction<SanityTypeProject[] | undefined>
  >;
  projectLists: SanityTypeProject[] | [];
}
const Card = ({ item, setProjectLists, projectLists }: CardProps) => {
  return (
    <div
      className={`${
        !item.showShulkerBox
          ? "thecard h-[250px] w-[557px]"
          : "lg:hidden thecard h-[250px] w-[557px]"
      } ${!item.showDescription ? "thecardHover" : "thecardNotHover"}`}
      onClick={() => {
        const newProjectList = projectLists.map((item1) => {
          if (item1.nameOfProject === item.nameOfProject) {
            return {
              ...item1,
              showDescription: !item1.showDescription,
            };
          }
          return item1;
        });

        setProjectLists(newProjectList);
      }}
    >
      <div className="theBack w-full" data-tip data-for="flipInfo">
        <div className="w-full h-[335px]   flex justify-center items-center border-[#F5F5F5]/50 rounded-[20px] border-[3px] bg-[#111418]">
          <h1 className="text-[#F5F5F5] z-[10] fonr-poppins p-5 font-[500] text-sm  leading-[27px] text-center">
            {item.descriptionOfProject}
          </h1>
        </div>
      </div>

      <img
        src={urlFor(item.imageOfProject).height(259).width(557).url()}
        className="h-[250px]  w-[557px] rounded-[20px] border-[3px] border-[#1B1D24] p-1"
        alt="image2"
      />
      <div className="theFront ">
        <div className="w-full mt-1 max-w-[557px] bg-white/10 border-[1px] border-[#1B1D24] rounded-[20px]">
          <div className="p-3 flex flex-col  justify-center  items-stretch">
            <div className="flex flex-row justify-between">
              <p className="text-white font-poppins font-[500] text-sm  leading-[27px] ">
                {item.nameOfProject}
              </p>
              <a
                href="https://www.youtube.com/watch?v=3HNyXCPDQ7Q&t=6702s"
                target={"_blank"}
                rel="noreferrer"
              >
                {item.linkOfProject && (
                  <a
                    href={item.linkOfProject}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:cursor-pointer  text-[#FC1111]  font-poppins font-[500] text-sm  leading-[27px] "
                  >
                    Code →
                  </a>
                )}
              </a>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row  flex-wrap space-x-2 lg:space-x-1">
                {item?.tagOfProject?.map((tag, index) => {
                  return (
                    <p
                      key={index}
                      className="text-white/50 font-poppins font-[500] text-xs leading-[27px] "
                    >
                      {tag.toUpperCase()}
                      {item?.tagOfProject &&
                      index !== item?.tagOfProject?.length - 1
                        ? ","
                        : ""}
                    </p>
                  );
                })}
              </div>
              {item.youtubeLinkOfProject && (
                <a
                  href={item.youtubeLinkOfProject}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:cursor-pointer text-[#FC1111] font-poppins font-[500] text-sm   leading-[27px] "
                >
                  Demo →
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
