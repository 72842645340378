import ProjectFilterHelper from "./ProjectFilterHelper";
import { Projects as SanityTypeProject } from "../../SanitySchemaTypes/schemaType";

interface ProjectFilterMenuProps {
  allProjects: {
    current: SanityTypeProject[] | [];
  };
  setProjectLists: React.Dispatch<
    React.SetStateAction<SanityTypeProject[] | undefined>
  >;
}

const ProjectFilterMenu = ({
  allProjects,
  setProjectLists,
}: ProjectFilterMenuProps) => {
  return (
    <div className="w-full ">
      <div className="hidden md:flex h-[72px] px-5 rounded-lg border-[1px] flex-col space-y-20 md:flex-row  md:space-y-0 md:justify-between items-center border-[#FFFFFF]/10 bg-[#111418]">
        <h1 className="text-2xl font-poppins font-[400] text-[#F5F5F5] leading-[37.5px] text-center md:text-left">
          Projects
        </h1>
        <div className="flex flex-row justify-between md:justify-center items-center space-x-5">
          <h1 className="text-xl font-poppins font-[700] text-[#F5F5F5] leading-[27px]">
            View
          </h1>
          <ProjectFilterHelper
            allProjects={allProjects}
            setProjectLists={setProjectLists}
          />
        </div>
      </div>

      <div className="md:hidden h-[72px] rounded-lg border-[1px] border-[#FFFFFF]/10 bg-[#111418]">
        <div className="w-full h-full flex flex-col space-y-20  justify-center items-center">
          <h1 className="text-2xl font-poppins font-[400] text-[#F5F5F5] leading-[37.5px] text-center ">
            Projects
          </h1>
        </div>
        <div className="mt-10 flex flex-row justify-between md:justify-center items-center space-x-5">
          <h1 className="text-xl font-poppins font-[700] text-[#F5F5F5] leading-[27px]">
            View
          </h1>
          <ProjectFilterHelper
            allProjects={allProjects}
            setProjectLists={setProjectLists}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectFilterMenu;
