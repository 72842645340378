import React, { useEffect, useState, useRef } from "react";
import line from "../../images/line.png";
import { urlFor } from "../../lib/client";
import { skillsQuery } from "../../lib/query";
import { Skills as SanityTypeSkills } from "../../SanitySchemaTypes/schemaType";
import "./skills.css";

const Skills = () => {
  const [skills, setSkills] = useState<SanityTypeSkills[]>();

  // todo: - chnage the type of the skillRef as HTMLDivElement!
  const skillRef = useRef<any>();

  const lastItem = 8;
  const [isSkillsInView, setSkillsInView] = useState(false);

  useEffect(() => {
    skillsQuery.then((data) => {
      setSkills(data);
    });
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSkillsInView(entry.isIntersecting);
    });

    observer.observe(skillRef?.current);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center space-y-10 ">
      <p className="font-poppins font-[500] text-xl leading-[27px] text-center text-[#F5F5F5]">
        Skills
      </p>
      <img src={line} alt="line" />
      <div
        ref={skillRef}
        className={`pt-8  lg:pt-32 w-full grid grid-cols-1 gap-5 items-center justify-center md:grid-cols-2 lg:grid-cols-4 `}
      >
        {skills?.map((item, index) => {
          return (
            <div
              key={index}
              //style={{ transitionDelay: "5000ms", backgroundColor: "red" }}
              className={`${
                index === lastItem ? "lg:col-span-4 w-full" : "col-span-1"
              } skill-item flex justify-center items-center  ${
                isSkillsInView
                  ? "skill-animation w-full"
                  : "skill-animation-show w-full"
              }  `}
            >
              <div className="w-full rounded-lg space-y-5  p-5 flex flex-col justify-center items-center max-w-[271.57px] h-auto bg-white/5">
                <div className="w-[120px] h-[109px] flex justify-center items-center">
                  <img
                    src={urlFor(item.languageImage).width(10).height(10).url()}
                    alt={item.languageName}
                  />
                </div>
                <p className="font-poppins font-[500] text-[#F5F5F5] leading-[27px] text-center">
                  {item.languageName}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Skills;
