import { useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Projects as SanityTypeProject } from "../../SanitySchemaTypes/schemaType";

import line from "../../images/line.png";
import { projectQuery } from "../../lib/query";
import "./project.css";
import ShulkerBox from "./ShulkerBox";
import Card from "./Card";
import ProjectFilterMenu from "./ProjectFilterMenu";

const Projects = () => {
  const [tooltip, showTooltip] = useState(true);
  const projectContainerRef = useRef<HTMLDivElement | null>(null);

  const [projectLists, setProjectLists] = useState<SanityTypeProject[]>();

  const allProjects = useRef<SanityTypeProject[] | []>([]);

  useEffect(() => {
    projectQuery.then((data) => {
      setProjectLists(data);
      allProjects.current = data;
    });
  }, []);

  return (
    <div>
      <div
        className="flex flex-col justify-center items-center space-y-20 lg:space-y-10"
        id="project"
      >
        <h1 className="font-poppins font-[500] text-xl leading-[27px] text-center text-[#F5F5F5]">
          Projects
        </h1>
        <img src={line} alt="line" />

        {allProjects && (
          <ProjectFilterMenu
            allProjects={allProjects}
            setProjectLists={setProjectLists}
          />
        )}

        <div
          data-aos="fade-up"
          ref={projectContainerRef}
          className="w-full items-center justify-items-center grid grid-cols-1 pb-36 gap-9 gap-y-36 md:grid-cols-2 pt-16"
        >
          {projectLists?.map((item, index) => {
            return (
              <div
                key={index}
                className=" relative flex justify-center items-center h-[250px] lg:w-[500px]"
              >
                {tooltip && (
                  <ReactTooltip
                    place="top"
                    id="registerTip"
                    type="dark"
                    effect="solid"
                    textColor="#fc1111"
                  >
                    Tap to open me 🔓
                  </ReactTooltip>
                )}

                <ShulkerBox
                  item={item}
                  setProjectLists={setProjectLists}
                  showTooltip={showTooltip}
                  projectLists={projectLists}
                  allProjects={allProjects}
                />

                <Card
                  item={item}
                  setProjectLists={setProjectLists}
                  projectLists={projectLists}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
