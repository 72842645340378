import { Experience as SanityTypeExperience } from "../../SanitySchemaTypes/schemaType";

import work from "../../images/work.svg";

export interface ExperienceProps {
  experience: SanityTypeExperience[];
}
const Experience = ({ experience }: ExperienceProps) => {

  const newExperience = experience.sort((a, b) => {
    if (a.order === undefined) {
        return -1;
    } else if (b.order === undefined) {
        return 1;
    } else {
        return a.order - b.order;
    }
  }); 

  return (
    <div className="relative flex flex-col justify-center items-center md:justify-start md:items-start space-y-16 md:space-y-0 md:flex-row md:space-x-48">
      <div className="md:absolute w-[150px] h-[150px] gap-10 flex justify-center items-center bg-[#111418] border-2 border-[#FC1111] rounded-full">
        <img src={work} alt="work" />
      </div>
      <div className="w-full flex flex-col space-y-8">
        {newExperience?.map((item, index) => {
          return (
            <div key={index} className="w-full flex flex-col space-y-2">
              <div className="w-full  flex flex-row  justify-between items-center">
                <p className="font-poppins font-[300] w-[50%] lg:w-[100%] text-xl leading-[20px] lg:text-3xl lg:leading-[48px]  md:text-left text-[#F5F5F5]">
                  {item.postion}
                </p>
                <p className="font-poppins font-[300] w-[50%] lg:w-[100%] text-xl leading-[20px] lg:text-3xl lg:leading-[48px]  text-end  text-[#F5F5F5]">
                  {item.yearOfStarting} -{" "}
                  {item.yearOfEnd !== 0 ? item.yearOfEnd : "Present"}
                </p>
              </div>

              <div>
                <div className="w-full  flex flex-row justify-between items-center">
                  <p className="font-poppins text-sm lg:text-xl font-[500] leading-[27px] text-white/50">
                    at {item.companyName}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Experience;
