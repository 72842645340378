import brandIcon from "../../images/brandIcon.svg";
import sanityLogo from "../../images/sanity.svg";
import reactLogo from "../../images/react.svg";
import tailwindLogo from "../../images/tailwind.svg";
import typeScriptLogo from "../../images/typeScript.svg";

const Footer = () => {
  return (
    <div>
      <div
        data-aos="zoom-in"
        className="z-[1] w-full flex flex-col mt-10 lg:mt-32 justify-center items-center space-y-5 lg:p-20 p-10"
      >
        <img src={brandIcon} width={191} height={191} alt="bandLogo" />
        <p className="hidden lg:block w-full text-[#FFFFFF]   font-poppins font-[500] text-center text-3xl lg:text-[42px]  leading-[46px] ">
          Built by Pranav Patel
        </p>

        <p className="lg:hidden w-full text-[#FFFFFF]   font-poppins font-[500] text-center text-3xl lg:text-[42px]  leading-[46px] ">
          Built by <br />
          Pranav Patel
        </p>
        <div className="flex flex-row lg:flex-row justify-center items-center space-x-2 lg:space-x-2 ">
          <p className=" text-[#FFFFFF]  font-poppins font-[500] text-center text-xl  leading-[45px] ">
            Built With
          </p>
          <div className="flex flex-row justify-center items-center space-x-2 lg:space-x-2 ">
            <span className="hidden lg:block">
              <img src={sanityLogo} width={100} height={100} alt="sanityLogo" />
            </span>
            <span className="lg:hidden">
              <img src={sanityLogo} width={40} height={40} alt="sanityLogo" />
            </span>
            <img src={reactLogo} width={25} height={25} alt="reacyLogo" />
            <img
              src={typeScriptLogo}
              width={25}
              height={25}
              alt="typeScriptLogo"
              className="rounded-sm"
            />
            <img src={tailwindLogo} width={25} height={25} alt="tailwindLogo" />
          </div>
        </div>

        <div>
          <p className=" text-[#FFFFFF]  font-poppins font-[500] text-center text-sm">
            © {new Date().getFullYear()} Pranav Patel
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
