import React from "react";

import About from "../About/About";
import Projects from "../Projects/Projects";
import Hero from "../Hero/Hero";
import Skills from "../Skills/Skills";
import Footer from "../Footer/Footer";

// this container will hold all the other componts
const Container = () => {
  return (
    <div className="px-5 md:px-10 lg:px-0 flex flex-col space-y-20 overflow-hidden">
      <Hero />
      <Skills />
      <Projects />
      <About />
      <Footer />
    </div>
  );
};

export default Container;
