import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import headerCircle from "./images/headerCircle.svg";
import Container from "./components/Container/Container";
import Header from "./components/Header/Header";
const App = () => {
  AOS.init({ duration: 2000, once: true });

  useEffect(() => {
    document.title = "Pranav Patel";
  }, []);
  return (
    <div className=" w-full absolute lg:h-[auto] bg-[#111418] ">
      <Header />

      <div className="max-w-[1190px] mx-auto">
        <div className="w-full  mx-auto bg-line_sm bg-contain lg:bg-cover md:bg-line_lg bg-repeat     ">
          <div
            className="hidden lg:block  w-full 2xl:hidden absolute overflow-hidden top-[-100px] right-[0%] blur-sm opacity-70"
            style={{
              backgroundImage: `url(${headerCircle})`,
              width: "597px",
              height: "597px",
              top: "-100px",
            }}
          ></div>

          <div className="w-full h-[auto] lg:h-[auto]  mx-auto ">
            <Container />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
