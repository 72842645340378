import React, { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

import { writePermissionClient } from "../../lib/client";
import "./about.css";

const ContactMeSchema = Yup.object().shape({
  name: Yup.string().required("what should I call you 🤔"),
  email: Yup.string()
    .email("i'm invigilating you, please enter valid email address 🧐")
    .required("where should I meassge you back 😅"),
  message: Yup.string().required("ah, you forgot to write some message 🤷"),
});

export interface ContactMeFormModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactMeFormModal = ({ setIsOpen }: ContactMeFormModalProps) => {
  const contactFormRef = useRef<HTMLDivElement>(null);

  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        id="contactForm"
        ref={contactFormRef}
        className={`  
           " fixed w-full -mt-[30%] lg:mt-[0] modelOpen  flex justify-center  items-center "
      }`}
      >
        <div className="z-[100]  border-2 border-[#fc1111]  w-[100%] lg:w-[60%]  py-12 px-5 lg:p-20 bg-[#111418]/100 rounded-2xl flex justify-center items-center">
          <IoMdClose
            size={30}
            color="white"
            className="absolute top-[2%] right-[5%] lg:top-[5%]  lg:right-[23%] hover:cursor-pointer"
            onClick={closeModal}
          />
          {!showLoadingAnimation && !showConfirmationMessage && (
            <Formik
              initialValues={{
                _type: "contact",
                name: "",
                message: "",
                email: "",
              }}
              validationSchema={ContactMeSchema}
              onSubmit={(values) => {
                setShowLoadingAnimation(true);
                writePermissionClient.create(values).then(() => {
                  setShowLoadingAnimation(false);
                  setShowConfirmationMessage(true);
                });
              }}
            >
              {({ errors, touched }) => (
                <Form className="w-full flex flex-col space-y-5">
                  <div>
                    <Field
                      name="name"
                      placeholder="Your name"
                      className="mt-0 border-[1px]  font-poppins text-sm  text-center py-3 px-10 rounded-lg w-[100%] bg-black text-[white]"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-center p-0 text-[#FC1111] font-poppins font-[500] text-sm   leading-[27px]">
                        {errors.name}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Field
                      name="email"
                      placeholder="Your email"
                      className=" font-poppins border-[1px] text-sm text-center py-3 px-10 rounded-lg w-[100%] bg-black text-[white]"
                    />
                    {errors.email && touched.email ? (
                      <p className="text-[#FC1111] text-center font-poppins font-[500] text-sm   leading-[27px]">
                        {errors.email}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Field
                      name="message"
                      placeholder="Your message"
                      className=" font-poppins border-[1px] text-sm text-center py-3 px-10 rounded-lg w-[100%] bg-black text-[white]"
                    />
                    {errors.message && touched.message ? (
                      <p className="text-[#FC1111] text-center font-poppins font-[500] text-sm   leading-[27px]">
                        {errors.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex w-full justify-center items-center">
                    <button
                      type="submit"
                      className="w-[200px] text-[1rem] lg:w-[300px] font-poppins text-[white] lg:text-xl h-[70px] flex flex-row  space-x-5 justify-center items-center p-3 border-2 border-[#FC1111]  bg-[#111418] rounded-lg hover:cursor-pointer"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {showLoadingAnimation && <CircularProgress />}
          {!showLoadingAnimation && showConfirmationMessage && (
            <div>
              <p className="py-20 lg:p-0 text-2xl text-[white] text-center font-poppins font-[500]  leading-[27px]">
                Thank you for your message, I will get back to you soon!
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactMeFormModal;
