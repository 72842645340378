import React, { useState } from "react";

import ContactMeFormModal from "./ContactMeFormModal";

const ContactMe = () => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div
        onClick={openModal}
        className="w-[340px] h-[100px] flex flex-row  space-x-5 justify-center items-center p-3 border-2 border-[#FC1111]  bg-[#111418] rounded-lg hover:cursor-pointer "
      >
        <p className=" text-xl  text-center   text-[#F5F5F5] font-poppins">
          Contact Me
        </p>
      </div>
      {modalIsOpen && <ContactMeFormModal setIsOpen={setIsOpen} />}
    </>
  );
};

export default ContactMe;
