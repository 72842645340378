import { Education as SanityTypeEducation } from "../../SanitySchemaTypes/schemaType";
import school from "../../images/school.svg";

export interface EducationProps {
  education: SanityTypeEducation[];
}

const Education = ({ education }: EducationProps) => {
  return (
    <div className="w-full  flex flex-col justify-center items-center md:justify-start md:items-start space-y-16 md:space-y-0 md:flex-row md:space-x-48">
      <div className="md:absolute w-[150px] h-[150px] gap-10 flex justify-center items-center bg-[#111418] border-2 border-[#FC1111] rounded-full">
        <img src={school} alt="school" />
      </div>
      <div className="w-full flex flex-col space-y-8">
        {education?.map((item, index) => {
          return (
            <div key={index} className="w-full flex flex-col space-y-2 ">
              <div className="w-full  flex flex-row  justify-between items-center">
                <p className="font-poppins font-[300]  text-xl leading-[20px] lg:text-3xl lg:leading-[48px] lg:text-center md:text-left text-[#F5F5F5]">
                  {item.degree}
                </p>
                <p className="font-poppins font-[300] text-xl leading-[20px] lg:text-3xl lg:leading-[48px]  lg:text-center md:text-left text-[#F5F5F5]">
                  {item.yearOfStarting} - {item.yearOfEnd}
                </p>
              </div>

              <div>
                <div className="w-full  flex flex-row justify-between items-center">
                  <p className="font-poppins text-sm lg:text-xl font-[500] leading-[27px] text-white/50">
                    {item.nameOfUniversity}
                  </p>
                  <p className="font-poppins text-sm lg:text-xl font-[500] leading-[27px] text-white/50">
                    {item.gpaObtain}
                  </p>
                </div>
              </div>

              {item.didAnyThingSpecial && (
                <div>
                  <p className="hover:cursor-pointer text-[#FC1111] font-poppins font-[500] text-sm   leading-[27px] ">
                    <a
                      href={item.didAnyThingSpecial[1]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>{item.didAnyThingSpecial[0]}</p>
                    </a>
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Education;
