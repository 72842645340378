import useSound from "use-sound";
import { Projects as SanityTypeProject } from "../../SanitySchemaTypes/schemaType";

import "./project.css";
import shulkerBottom from "../../images/shuklerBottom.png";
import shulkerTop from "../../images/shulkerTop.png";
import shulkerSound from "../../sounds/open-shulker-box.mp3";

interface ShulkerBoxProps {
  item: SanityTypeProject;
  setProjectLists: React.Dispatch<
    React.SetStateAction<SanityTypeProject[] | undefined>
  >;
  showTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  projectLists: SanityTypeProject[] | [];
  allProjects: {
    current: SanityTypeProject[] | [];
  };
}

const ShulkerBox = ({
  item,
  setProjectLists,
  showTooltip,
  projectLists,
  allProjects,
}: ShulkerBoxProps) => {
  const [sound] = useSound(shulkerSound);
  return (
    <div
      className={`${
        item.showShulkerBox ? "absolute  w-[50%] lg:block" : "hidden"
      } hidden ${item.showAnimation ? "entireShulerBox" : ""}`}
      onClick={() => {
        const newProjectList = projectLists.map((item1) => {
          if (item1.nameOfProject === item.nameOfProject) {
            return { ...item1, showAnimation: true };
          }
          return item1;
        });
        sound();
        setProjectLists(newProjectList);
        showTooltip(false);
        const filterId: string[] = [];
        setTimeout(() => {
          const newProjectList = projectLists.map((item1) => {
            if (item1.nameOfProject === item.nameOfProject) {
              filterId.push(item1._id);
              return { ...item1, showShulkerBox: false };
            }
            return item1;
          });

          showTooltip(true);

          setProjectLists(newProjectList);

          const allProjectUpdatedList = allProjects.current.map((item) => {
            if (item._id === filterId[0])
              return { ...item, showShulkerBox: false };
            return item;
          });

          allProjects.current = allProjectUpdatedList;
        }, 500);
      }}
      data-tip
      data-for="registerTip"
      onMouseEnter={() => showTooltip(true)}
      onMouseLeave={() => {
        showTooltip(false);
        setTimeout(() => showTooltip(true), 50);
      }}
    >
      <img
        src={shulkerBottom}
        alt="shulkerBottom"
        className={` ${
          !item.showAnimation
            ? " bg-[white]/70 rounded-2xl shadow-lg shadow-[white]"
            : " rounded-2xl shadow-lg  shadow-[white]/10 bottomShulkerAnimated "
        } `}
      />

      <div
        className={`relative mt-[-101%] shulkerTop  ${
          item.showAnimation ? "shulkerBox  " : "updown"
        }`}
      >
        <img
          src={shulkerTop}
          alt="shulkerTop"
          className={`${
            !item.showAnimation
              ? "rounded-2xl border-t-white  border-t-[1px] "
              : "rounded-2xl border-t-white  border-t-[1px] border-r-[1px] border-l-[1px]"
          }`}
        />
      </div>
    </div>
  );
};

export default ShulkerBox;
